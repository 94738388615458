<template>
  <div class="entry-div">
    <b-card class="entry-card">
      <b-row class="justify-content-center">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="entry-button"
          variant="primary"
          @click="timeSelect('A')"
        >
          A
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="entry-button"
          variant="primary"
          @click="timeSelect('B')"
        >
          B
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="entry-button"
          variant="primary"
          @click="timeSelect('C')"
        >
          C
        </b-button>
      </b-row>
      <b-row class="justify-content-center">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="entry-button"
          variant="primary"
          @click="timeSelect('D')"
        >
          D
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="entry-button"
          variant="primary"
          @click="timeSelect('E')"
        >
          E
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="entry-button"
          variant="primary"
          @click="timeSelect('F')"
        >
          F
        </b-button>
      </b-row>
      <b-row class="justify-content-center">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="entry-button"
          variant="primary"
          @click="timeSelect('goBack')"
        >
          <feather-icon icon="ArrowLeftIcon" size="1.5x" />
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="entry-button no-entry-button"
          variant="primary"
          @click="timeSelect('Ø')"
        >
          <span>Ø</span>
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="entry-button"
          variant="primary"
          @click="timeSelect('goNext')"
        >
          <feather-icon
            :icon="rows.length - 1 == index ? 'CheckIcon' : 'ArrowRightIcon'"
            size="1.5x"
          />
        </b-button>
      </b-row>
      <!-- <b-row class="justify-content-center">
        <b-button class="entry-button" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary" @click="timeSelect('send')"> TAMAM </b-button>
      </b-row> -->
    </b-card>
  </div>
</template>
<script>
import { BButtonGroup, BButton, BCard, BRow, BCol } from "bootstrap-vue";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";
import Ripple from "vue-ripple-directive";

export default {
  props: ["index", "rows"],
  components: {
    BButtonGroup,
    BButton,
    BCard,
    BRow,
    BCol,
    FeatherIcon,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      mistake: "-", // A, B, C, D, E, F
    };
  },
  methods: {
    timeSelect(string) {
      this.mistake = string;
      this.$emit("mistake", this.mistake);
      // this.$emit('mistake', 'send')
    },
    checkIcon() {
      return;
    },
  },
};
</script>
<style scoped>
.entry-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.entry-card {
  width: 100%;
  max-width: 800px;
}

.entry-button {
  width: 30%;
  margin: 1%;
  height: 75px;
  font-size: 1.5rem;
}

.no-entry-button {
  font-size: 2rem;
}
</style>
