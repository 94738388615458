<template>
  <div class="entry-div">
    <b-card class="entry-card">
      <b-row class="justify-content-center">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="entry-button"
          variant="primary"
          @click="timeSelect('A')"
        >
          A
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="entry-button"
          variant="primary"
          @click="timeSelect('B')"
        >
          B
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="entry-button"
          variant="primary"
          @click="timeSelect('C')"
        >
          C
        </b-button>
      </b-row>
      <b-row class="justify-content-center">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="entry-button"
          variant="primary"
          @click="timeSelect('D')"
        >
          D
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="entry-button"
          variant="primary"
          @click="timeSelect('E')"
        >
          E
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="entry-button"
          variant="primary"
          @click="timeSelect('F')"
        >
          F
        </b-button>
      </b-row>
      <b-row class="justify-content-center">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="entry-button"
          :variant="checkPrevious() ? 'primary' : 'secondary'"
          @click="timeSelect('goBack')"
        >
          <feather-icon v-if="checkPrevious()" icon="ArrowLeftIcon" size="1.5x" />
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="entry-button no-entry-button"
          variant="primary"
          @click="timeSelect('Ø')"
        >
          <span>Ø</span>
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="entry-button"
          variant="primary"
          @click="timeSelect('goNext')"
        >
          <feather-icon icon="ArrowRightIcon" size="1.5x" />
        </b-button>
      </b-row>
      <b-row class="justify-content-center">
        <!-- <b-button class="entry-button" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary" @click="timeSelect('goMistake')">
          TAMAM
        </b-button> -->
      </b-row>
      <b-row class="justify-content-center">
        <!-- <b-button class="entry-button" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary"> TAMAM </b-button> -->
      </b-row>
    </b-card>
  </div>
</template>
<script>
import { BButtonGroup, BButton, BCard, BRow, BCol } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";

export default {
  props: ["index", "rows"],
  components: {
    BButtonGroup,
    BButton,
    BCard,
    BRow,
    BCol,
    FeatherIcon,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      time: "-", // A, B, C, D, E, F
    };
  },
  methods: {
    timeSelect(string) {
      // console.log(string);
      this.time = string;
      this.$emit("time", this.time);
      // if(string.length == 1)
      // this.$emit("time", "goMistake");
    },
    checkPrevious() {
      let i = this.index;
      while (i > 0) {
        i--;
        if (!this.rows[i].solved) return true;
      }
      return false;
    },
  },
};
</script>
<style scoped>
.entry-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.entry-card {
  width: 100%;
  max-width: 800px;
}

.entry-button {
  width: 30%;
  margin: 1%;
  height: 75px;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-entry-button {
  font-size: 2rem;
  display: flex;
}
</style>
