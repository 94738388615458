<template>
  <div>
    <b-card>
      <b-row class="justify-content-md-center" style="max-width: 750px">
        <b-col>
          <b-form-group>
            <b-form-input id="largeInput" v-model="exerciseuID" size="lg" placeholder="Test Numarasını Giriniz" />
          </b-form-group>
        </b-col>
        <b-col class="">
          <b-button variant="primary" @click="getExercise">
            {{ $t("Input Answers") }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-modal v-model="modalShow" centered no-close-on-backdrop ok-only hide-footer>
      <template v-slot:modal-header>
        <h4 class="modal-title">
          <b>{{ $t("Answer Input") }}</b>
        </h4>
        <div class="ml-auto">
          <b-button :variant="rowEntered ? 'primary' : 'outline-primary'" ref="saveButton" @click="sendResults"
            v-ripple.200="'rgba(255, 255, 255, 0.5)'" class="p-1">
            <feather-icon icon="SaveIcon"></feather-icon>
          </b-button>
          <b-button variant="outline-primary" @click="modalShow = !modalShow" class="border-secondary p-1 ml-1">
            <feather-icon icon="XIcon"></feather-icon>
          </b-button>
        </div>
      </template>
      <b-card>
        <!-- <b-row>
          <b-col> {{ $t("Exrecise ID") }}: </b-col>
          <b-col> {{ exerciseuID }} </b-col>
          <b-col>
            <h6>Süre:</h6>
          </b-col>
          <b-col>
            <h6>{{ currentTime }}</h6>
          </b-col>
          <b-col>
            <h6>Hata:</h6>
          </b-col>
          <b-col>
            <h6>{{ currentMistake }}</h6>
          </b-col>
        </b-row> -->
        <vue-good-table style-class="vgt-table condensed bordered customstyle" :columns="columns" :rows="rows"
          :sort-options="{ enabled: false }" max-height="290px">
          <template slot="table-row" slot-scope="props">
            <!-- Column: Exercise No -->
            <div v-if="props.column.field === 'uID'" class="text-nowrap">
              <span v-if="props.row.uID" class="text-nowrap" :id="`row-${props.index}`">
                <!-- <feather-icon
                  v-if="rowArrayIndex == props.index"
                  icon="ArrowRightIcon"
                  size="1.5x"
                /> -->
                <b-button :variant="
                    props.row.solved
                      ? 'success'
                      : props.index == rowArrayIndex
                      ? 'primary'
                      : props.row.time != '-' && props.row.mistake != '-'
                      ? 'info'
                      : 'outline-info'
                  " style="padding: 0.26rem 0.8rem; margin: 0 0.5rem"
                  @click="props.row.solved ? '' : changeFocus(props.index, 'time')">
                  <span :class="{
                      'feather-white': props.row.time != '-' && props.row.mistake != '-',
                    }">{{ props.row.uID }}
                    <feather-icon :icon="
                        props.index == rowArrayIndex
                          ? 'ChevronsLeftIcon'
                          : props.row.solved
                          ? 'CheckIcon'
                          : 'Edit3Icon'
                      "></feather-icon>
                  </span>
                </b-button>
                <!-- <b-button v-if="!props.row.solved" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  :variant="rowArrayIndex == props.index ? 'primary' : 'outline-primary'" size="sm"
                  style="padding: 0.26rem 0.8rem; margin: 0 0.5rem" @click="
                    rowArrayIndex = props.index;
                    modalSwitch = 'time';
                  ">
                  <feather-icon icon="ArrowRightIcon" size="12" class="text-body"
                    :class="{ 'feather-white': rowArrayIndex == props.index }" />
                </b-button>
                <b-badge variant="success" style="margin: 0 0.5rem; border-radius: 0.5rem" v-else>
                  <feather-icon icon="CheckIcon" size="12" class="text-body feather-white" />
                </b-badge> -->
              </span>
            </div>
            <!-- Column: Exercise time -->
            <div v-if="props.column.field === 'time'" class="text-nowrap">
              <span v-if="props.row.time" class="text-nowrap">
                <feather-icon v-if="rowArrayIndex == props.index && modalSwitch == 'time'" icon="ChevronRightIcon"
                  size="1.5x" />
                <b-badge :variant="statusVariantLetter(props.row.time)" :pill="props.row.solved ? true : false"
                  @click="props.row.solved ? '' : changeFocus(props.index, 'time')">
                  <span class="uid-text">{{ props.row.time }}</span>
                </b-badge>
                <feather-icon v-if="rowArrayIndex == props.index && modalSwitch == 'time'" icon="ChevronLeftIcon"
                  size="1.5x" />
              </span>
            </div>
            <!-- Column: Exercise Mistake -->
            <div v-if="props.column.field === 'mistake'" class="text-nowrap">
              <span v-if="props.row.mistake" class="text-nowrap">
                <feather-icon v-if="rowArrayIndex == props.index && modalSwitch == 'mistake'" icon="ChevronRightIcon"
                  size="1.5x" />
                <b-badge :variant="statusVariantLetter(props.row.mistake)" :pill="props.row.solved ? true : false"
                  @click="props.row.solved ? '' : changeFocus(props.index, 'mistake')">
                  <span class="uid-text">{{ props.row.mistake }}</span>
                </b-badge>
                <feather-icon v-if="rowArrayIndex == props.index && modalSwitch == 'mistake'" icon="ChevronLeftIcon"
                  size="1.5x" />
              </span>
            </div>
            <!-- <div v-if="props.column.field === 'action'" class="text-nowrap">
              <span v-if="props.row.mistake" class="text-nowrap">
                <b-button
                  v-if="!props.row.solved"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  :variant="rowArrayIndex == props.index ? 'primary' : 'outline-primary'"
                  size="sm"
                  @click="
                    rowArrayIndex = props.index;
                    modalSwitch = 'time';
                  "
                >
                  <feather-icon
                    icon="ArrowLeftIcon"
                    size="12"
                    class="text-body align-left"
                    :class="{ 'feather-white': rowArrayIndex == props.index }"
                  />
                </b-button>
                <feather-icon
                  v-else
                  icon="CheckIcon"
                  size="12"
                  class="text-body align-left"
                />
              </span>
            </div> -->
          </template>
        </vue-good-table>
        <div v-if="modalSwitch == 'send'">
          <!-- <b-row class="entry-row">
            <b-col>Seçilen Süre: </b-col>
            <b-col>
              {{ currentTime }}
            </b-col>
            <b-col>Seçilen Hata: </b-col>
            <b-col>
              {{ currentMistake }}
            </b-col>
          </b-row> -->
          <b-row class="justify-content-around mt-5">
            <b-button variant="primary" @click="goBack" style="width: 45%">
              <feather-icon icon="SkipBackIcon" size="2x"></feather-icon>
            </b-button>
            <b-button variant="primary" @click="sendResults" style="width: 45%">
              <feather-icon icon="SaveIcon" size="2x"></feather-icon>
            </b-button>
          </b-row>
        </div>
      </b-card>
      <!--<b-card>
        <b-row class="entry-row" v-if="modalSwitch == 'time'"> </b-row>
        <b-row class="entry-row" v-if="modalSwitch == 'mistake'"> </b-row>
      </b-card> -->
      <div v-if="modalSwitch == 'time'">
        <UsersExerciseEntryTime @time="getTime" :index="rowArrayIndex" :rows="rows" />
        <!-- <b-button variant="primary" @click="modalSwitchF(1)"> </b-button> -->
      </div>
      <div v-if="modalSwitch == 'mistake'">
        <UsersExerciseEntryMistake @mistake="getMistake" :index="rowArrayIndex" :rows="rows" />
        <!-- <b-button variant="primary" @click="modalSwitchF(2)"> </b-button> -->
      </div>
    </b-modal>
  </div>
</template>
<script>
import UsersExerciseEntryTime from "@/views/apps/user/users-exercise/UsersExerciseEntryTime.vue";
import UsersExerciseEntryMistake from "@/views/apps/user/users-exercise/UsersExerciseEntryMistake.vue";
import {
  BModal,
  BButton,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BBadge,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import axios from "@axios";
import Ripple from "vue-ripple-directive";
import FeatherIcon from "../../../../@core/components/feather-icon/FeatherIcon.vue";

const {
  axiosRoutes: { exercise: path },
} = require("/G_CONFIG");

export default {
  props: {
    subjectID: {
      type: String,
      required: false,
      default: "-",
    },
  },
  directives: {
    Ripple,
  },
  components: {
    UsersExerciseEntryTime,
    UsersExerciseEntryMistake,
    BModal,
    BButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    VueGoodTable,
    BBadge,
    FeatherIcon,
  },
  data() {
    return {
      arrow: [],
      rowArrayIndex: 0,
      modalShow: false,
      modalShow2: false,
      modalSwitch: "time",
      time: "",
      mistake: "",
      currentTime: "",
      currentMistake: "",
      exerciseuID: null,
      search: null,
      idType: "",
      columns: [
        {
          label: "Egzersiz No:",
          field: "uID",
          tdClass: "text-center w-25",
          thClass: "text-center w-25",
        },
        {
          label: "Süre",
          field: "time",
          tdClass: "text-center w-25",
          thClass: "text-center w-25",
        },
        {
          label: "Hata",
          field: "mistake",
          tdClass: "text-center w-25",
          thClass: "text-center w-25",
        },
        // {
        //   label: "Giriş",
        //   field: "action",
        //   tdClass: "text-center w-25",
        //   thClass: "text-center w-25",
        // },
      ],
      rows: [{ uID: "0", time: "0", mistake: "0", trClass: "text-center w-25" }],
    };
  },
  computed: {
    /* rowStyleClassFn(row) {
      console.log("row", row.uID);
      console.log("row UID", this.rows[this.rowArrayIndex].uID);
      console.log();
      if (row.uID == this.rows[this.rowArrayIndex].uID) {
        return (this.arrow = true);
      }
    }, */
    rowEntered() {
      var entered =
        this.rows.filter((row) => {
          return row.time != "-" && row.mistake != "-";
        }).length == this.rows.length
          ? true
          : false;
      // if (entered) {
      //   let $el = this.$refs.saveButton;
      //   let ev = new Event("mousedown");
      //   let offset = $el.getBoundingClientRect();
      //   ev.clientX = offset.left + offset.width / 2;
      //   ev.clientY = offset.top + offset.height / 2;
      //   $el.dispatchEvent(ev);

      //   setTimeout(function () {
      //     $el.dispatchEvent(new Event("mouseup"));
      //   }, 100);
      // }
      return entered;
    },
    statusVariantLetter() {
      const statusColorLetter = {
        /* eslint-disable key-spacing */
        A: "success",
        B: "info",
        C: "primary",
        D: "warning",
        E: "danger",
        F: "dark",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColorLetter[status];
    },
  },
  methods: {
    changeFocus(index, modal) {
      this.rowArrayIndex = index;
      this.modalSwitch = modal;
    },
    sendID(id, seansType) {
      this.exerciseuID = id;
      this.idType = seansType;
      // console.log("id type:", this.idType);
      // console.log("id:", this.exerciseuID);
      this.getExercise();
    },
    getTime(value) {
      if (value != "goBack" && value != "goNext") {
        this.rows[this.rowArrayIndex].time = value;
        this.modalSwitchF(1);
      } else if (value == "goNext") {
        this.modalSwitchF(1);
      } else if (value == "goBack") {
        this.rowArrayIndex--;
        while (this.rowArrayIndex > 0 && this.rows[this.rowArrayIndex].solved) {
          this.rowArrayIndex--;
        }
        this.modalSwitchF(1);
        if (this.rows[this.rowArrayIndex].solved) {
          while (
            this.rows[this.rowArrayIndex].solved &&
            this.rowArrayIndex <= this.rows.length
          ) {
            this.rowArrayIndex++;
          }
          this.modalSwitchF(1);
        }
      }
      /* this.modalSwitch = "mistake"; */
      console.log(value);
    },
    goBack() {
      this.rowArrayIndex = 0;
      while (this.rowArrayIndex >= 0 && this.rows[this.rowArrayIndex].solved) {
        this.rowArrayIndex++;
      }
      this.modalSwitch = "time";
    },
    getMistake(value) {
      if (value != "goNext" && value != "send" && value != "goBack") {
        this.rows[this.rowArrayIndex].mistake = value;

        // this.rowArrayIndex++;
        do {
          this.rowArrayIndex++;
        } while (
          this.rowArrayIndex < this.rows.length &&
          this.rows[this.rowArrayIndex].solved
        );

        this.modalSwitchF(2);
      } else if (value == "goNext" || value == "send") {
        // this.rowArrayIndex++;
        do {
          this.rowArrayIndex++;
        } while (
          this.rowArrayIndex < this.rows.length &&
          this.rows[this.rowArrayIndex].solved
        );
        this.modalSwitchF(2);
      } else if (value == "goBack") {
        // this.rowArrayIndex--;
        this.modalSwitchF(2);
      }
    },
    modalSwitchF(value) {
      if (this.rowArrayIndex == this.rows.length) {
        this.modalSwitch = "send";
      }
      if (this.modalShow == false) {
        this.rowArrayIndex = 0;
      }
      if (value == 1) {
        this.modalSwitch = "mistake";
      } else if (value == 2) {
        if (this.rows.length > 1 && this.rowArrayIndex < this.rows.length) {
          this.modalSwitch = "time";
          document.getElementById(`row-${this.rowArrayIndex}`).scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        } else this.modalSwitch = "send";
      }
    },
    async sendResults() {
      // this.rows.forEach((element) => {
      // console.log(element);
      // if (!element.solved && element.time != "-" && element.mistake != "-") {
      console.log("this.rows", this.rows);
      await axios.post(
        `${path.resultList /* + element.uID*/}`,
        // {
        this.rows
        // time: element.time,
        // mistake: element.mistake,
        // }
      );
      // }
      // });
      this.time = "";
      this.mistake = "";
      this.currentTime = "";
      this.currentMistake = "";
      this.exerciseuID = null;
      this.modalShow = false;
      await this.$swal({
        title: "Egzersiz Sonuçları Gönderildi",
        icon: "success",
        showConfirmButton: false,
        timer: 2000,
      });
      this.$emit("sendComplete");
    },
    async getExercise() {
      if (this.rowArrayIndex > 0) {
        this.rowArrayIndex = 0;
      }
      if (this.$route.params.id === undefined && this.idType != "live") {
        const checkRes = await axios.post(`${path.check}${this.exerciseuID}`);

        if (checkRes.data.exists === true) {
          console.log("checkRes.data.exists", checkRes.data);
          this.modalShow = true;
          this.modalSwitch = "time";
        } else {
          this.sError();
        }
      } else {
        // console.log(`subjectID`);
        const res = await axios.get(
          `${path.list}${this.idType == "live" ? this.subjectID : this.$route.params.id}`
        );
        // console.log("res.data", res.data);
        if (this.idType == "seans" || this.idType == "live") {
          // console.log(this.exerciseuID, res.data[this.exerciseuID]);
          const children = res.data[this.exerciseuID].children.map((item) => ({
            uID: item.uID,
            time: item.time || "-",
            mistake: item.mistake || "-",
            solved: item.solved,
          }));
          this.rows = children;
          // const solved = children.filter.reduce((acc, cur) => {
          //   return acc + cur.solved ? 1 : 0;
          // }, 0);
          // if (solved > 0) {
          //   this.rowArrayIndex = solved;
          // }
          this.modalShow = true;
          this.modalSwitch = "time";
          while (
            this.rowArrayIndex < this.rows.length &&
            this.rows[this.rowArrayIndex].solved
          ) {
            this.rowArrayIndex++;
          }
          return;
        }

        for (let index = 0; index < res.data.length; index++) {
          const filteredArray = res.data[index].children.filter(
            (element) => element.uID === this.exerciseuID
          );
          if (filteredArray.length > 0) {
            var exists = true;
            break;
          }
        }
        if (exists) {
          this.modalShow = true;
          this.modalSwitch = "time";
        } else {
          this.sError();
        }
      }
    },
    sError() {
      this.$swal({
        title: "Egzersiz No Hatalı!",
        html: " Egzersiz Numrası Hatalı Lütfen Girdiğiniz Numarayı Kontrol Ediniz!",
        icon: "error",

        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

/* .seans-header {
  margin-left: 10px;
} */
.uid-text {
  color: #fff !important;
}
</style>
<style scoped>
.feather-white {
  color: #fff !important;
}
</style>
